<template>
  <div class="auth-wrapper auth-v2">
    
    <IntroComponent @showLoginEv="showLogin" />
    <OnboardingComponent v-if="showOnBoarding" :isVisible="showOnBoarding" @close="closeOnboarding" />
    
    <div v-if="displayLogin" class="login-form">
      <div class="lang-switch">
        <div @click="selectLang('fr')" :class="{active:language=='fr'}">FR</div>
        <img src="@/assets/images/world.svg" />
        <div  @click="selectLang('en')" :class="{active:language=='en'}">EN</div>
      </div>
      <div class="title-top big">
          Welcome to your Dashboard.
          <div class="grey">
            Track Performance, Manage Tools, And Optimize Activities With Ease.
          </div>
      </div>
       <!-- form -->
       <div class="form">
            <form
              class="auth-login-form mt-2"
              @submit.prevent="validationForm"
            >
              <!-- email -->
             
               
                  <input
                    id="login-email"
                    required
                    v-model="userEmail"
                  
                    name="login-email"
                    placeholder="E-mail"
                  />
                  
              <!-- forgot password -->
                <input
                  id="login-password"
                  v-model="password"
                  required
                  class="form-control-merge"
                  type="password"
                  name="login-password"
                  placeholder="Password"
                />
              <br /><br />
              <button type="submit" class="button submit submit-login" :class="{disabled:requiredFieldsOk==false}">Connexion</button>
            </form>

          </div>
        
    </div>
  </div>
</template>

<script>
import { checkLogin } from '../auth/utils'
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BFormCheckbox, BCardText, BCardTitle, BImg, BForm, BButton,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {Auth} from '@simb2s/senseye-sdk'
import IntroComponent from './IntroComponent.vue'
import OnboardingComponent from './onboarding/OnboardingComponent.vue'

export default {
  components: {
    BRow,
    BCol,
    OnboardingComponent,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    IntroComponent,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      showOnBoarding:false,
      status: '',
      language:'en',
      displayLogin:false,
      displayOnboarding:false,
      password: '',
      requiredFieldsOk: false,
      userEmail: '',
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      // validation rulesimport store from '@/store/index'
      required,
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    selectLang(lang)
    {
      this.language=lang
    },
    closeOnboarding(){
      this.showOnBoarding=false
    },
    showLogin() {
      this.displayLogin=true
    },
    async validationForm() {
      try {
        let Parameters={}
        Parameters.username=this.userEmail
        Parameters.password=this.password
        let user = await Auth.login(Parameters)
        localStorage.token_senseye_auth=user.token;
        localStorage.refresh_token_senseye_auth=user.refresh_token;
        // this.$toast({
        //   component: ToastificationContent,
        //   props: {
        //     title: 'Authentification réussie !',
        //     icon: 'CheckIcon',
        //     variant: 'success',
        //   },
        // })
        this.$forceUpdate();
        // setTimeout(() => {
        //   this.$router.push('/')
        //   setTimeout(() => {
        //     document.location.reload()
        //   }, 1000);
        // }, 2000);
        

        let user_datas = await checkLogin()
        localStorage.id_user_senseye_auth=user_datas.id
        this.$user.name = user_datas.name;
        this.$user.id = user_datas.id;
        this.$user.email = user_datas.email;
        this.$user.roles = user_datas.roles;
        this.$user.x = user_datas.x;
        this.$user.stripe_id = user_datas.stripe_id;
        this.$user.beta_access = user_datas.beta_access;
        this.$user.biography = user_datas.biography;
        this.$user.brand_logo = user_datas.brand_logo;
        this.$user.brand_name = user_datas.brand_name;
        this.$user.onboarding_finished = user_datas.onboarding_finished;
        this.$user.created_at = user_datas.created_at;
        this.$user.customer_stripe_id = user_datas.customer_stripe_id;
        this.$user.facebook = user_datas.facebook;
        this.$user.instagram = user_datas.instagram;
        this.$user.linkedin = user_datas.linkedin;
        this.$user.main_user = user_datas.main_user;
        this.$user.password_changed = user_datas.password_changed;
        this.$user.phone = user_datas.phone;
        this.$user.stl_files = user_datas.stl_files;
        this.$user.surname = user_datas.surname;
        this.$user.username = user_datas.username;

        this.displayLogin=false

        if(user_datas.onboarding_finished!=true)
        {
          this.showOnBoarding=true
        }
        else
        {
          this.$router.push('/')
        }

      } catch (error) {

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Vos identifiants sont incorrects !',
            icon: 'AlertCircleIcon',
            variant: 'warning',
          },
        })
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';

@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
  
}

.login-form
{
  animation: fadein 0.5s ease-in;
  color:#fff;
  min-width: 900px;
  position: absolute;
  opacity:1;
  z-index: 9999;
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);
}
input::placeholder, textarea::placeholder
{
  color:#fff;
  font-style: italic;
}
.auth-wrapper input,.auth-wrapper textarea {
   color:#fff;
  background: rgba(57, 57, 57, 0.1) 0% 0% no-repeat padding-box;
  border: 0.5px solid rgba(255, 255, 255, 0.5);
  border-radius: 3px;
  opacity: 1;
  font-size: 16px;
  margin-top:15px;
  backdrop-filter: blur(30px);
  -webkit-backdrop-filter: blur(30px);
  padding:10px 20px;
}
.button.submit:hover
{
    border:1px solid #000;
    background:#000;
    color:#fff;
}
.submit-login
{
  margin-left:auto !important;
  margin-right:auto !important;
  max-width: 300px;
}
.button.submit
{
 
    border: 1px solid rgba(255, 255, 255, 1);
    background: rgba(255, 255, 255, 1) 0% 0% no-repeat padding-box;
    color:#000;

    border: 1px solid rgba(255, 255, 255, 1);
}
.button
{
    transition: all 0.3s;
    margin-left:10px;
    margin-right:10px;
    min-width: 200px;
    border-radius: 10px;
    text-align: center;
    cursor: pointer;
    font-size:18px;
    padding:10px 20px;
}
.form form
{
    margin-top: 50px;
    display:flex;
    flex-direction: column;
}
.title-top.big 
{
    font-size: 60px;
}
.title-top
{
    margin-top:50px;
    line-height: 1;
    font-size:45px;
    text-align: center;
}
.title-top .grey {
    color:rgba(153, 153, 153, 1);
}
.lang-switch img 
{
  width:40px;
  margin-left:30px;
  margin-right:30px;
}
.lang-switch .active 
{
  border-bottom: 1px solid #fff;
}
.lang-switch 
{
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size:28px;
}
@media screen and (max-width:768px) {
  .title-top.big
  {
    font-size:23px;
  }
  .login-form 
  {
    padding-top:100px;
    min-width: 90vw;
    padding-bottom:100px;
    max-height:80vh;
    overflow: auto;
  }
  .button {
    font-size:15px;
  }
  .lang-switch
  {
    font-size:17px;
  }
  .lang-switch img
  {
    width:28px;
  }
  input 
  {
    font-size:13px;
  }
}
@media screen and (min-width:768px) and (max-width:1024px) {
  .title-top.big
  {
    font-size:35px;
  }
  .login-form 
  {
    min-width: 90vw;
  }
  .button {
    font-size:15px;
  }
  .lang-switch
  {
    font-size:22px;
  }
  .lang-switch img
  {
    width:35px;
  }
  input 
  {
    font-size:15px;
  }
}

@media screen and (min-width:1024px) and (max-width:1400px) {
  .title-top.big
  {
    font-size:38px;
  }
  .login-form 
  {
    min-width: 80vw;
  }
  .button {
    font-size:16px;
  }
  .lang-switch
  {
    font-size:22px;
  }
  .lang-switch img
  {
    width:35px;
  }
  input 
  {
    font-size:16px;
  }
}

</style>
